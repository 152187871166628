/**********************************
 * Adobe Tagging 처리 middleware
 *  - pageLoad 이벤트 태깅 처리
 **********************************/
import Log from '@/utils/log';

export default function (context) {
  Log.log('[미들웨어-adobeTagging] AdobeTagging START');
  // context?.$adobeUtil?.pageTagging();
  Log.log('[미들웨어-adobeTagging] AdobeTagging END');
}
